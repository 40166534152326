import React from 'react'
import { Typography } from 'antd'
import LandingLayout from '@layouts/LandingLayout'
import Helmet from 'react-helmet'

export default () => (
  <LandingLayout>
    <div className='flex flex-col items-center'>
      <Helmet>
        <title>Admin Interface | Proquero [DEV]</title>
      </Helmet>
      <Typography.Title className='text-primary-color'>
        Admin Interface
      </Typography.Title>
      <Typography.Title level={2} className='text-primary-color'>
        Proquero v0.71
      </Typography.Title>
      <Typography.Title level={1} className='text-gray-600'>
        Welcome, CTW Member! Thanks for using Proquero.
      </Typography.Title>
      <Typography.Title level={4} className='text-gray-600'>
        <p>This is the place where members with Admin permissions can make updates to the system.</p>

        <p>To get started, we want to get the Vendor list cleaned up.</p>

        <p>Go to <a href="https://proquero.com/admin/vendors">https://proquero.com/admin/vendors</a> to make your additions, deletions, and modifications.</p>
      </Typography.Title>
      {/* <img src='/cover.png' alt='logo' className='mt-10' /> */}
    </div>
  </LandingLayout>
)
